@import '~bootstrap-icons/font/bootstrap-icons';
html {
  position: relative;
  min-height: 100%;
  height: 100%;
  scroll-behavior: smooth;
}
.btn.focus, .btn:focus {
  outline: none;
  box-shadow: none;
}
/* footer {
position: absolute;
bottom: 0;
width: 100%;
height: 194px;
line-height: 60px;
background-color: #f5f5f5;
} */
body{
  font-family: 'Source Sans Pro', sans-serif;
  color: #747474;
  letter-spacing: 0.17px;
  font-size: 12px;
  height: 100%;
  /* padding-bottom: 194px; */
}
#root, #root > div:not(.Toastify) {
  height: 100%;
}
a{
  color: #232782;
  -webkit-appearance: none !important;
}
a:hover{
  text-decoration: none;
  color: #232782;
}
img{
  vertical-align: text-bottom;
}
hr{
  margin-top: 10px;
  margin-bottom: 10px;
}
h1,h2,h3,h4,h5{ margin: 0; padding: 0; line-height: normal;}
h1{
  font-weight: 600;
  font-size: 38px;
  color: #232782;
}
h2{
  font-size: 28px;
  font-weight: 600;
  color: #232782;
}
h2 span{
  font-size: 18px;
  color: #5A5959A1;
}
h3{
  font-size: 24px;
  font-weight: 600;
  color: #232782;
}
h4, ul.list-items li{
  font-size: 15px;
  margin-bottom: 5px;
}
h5{
  font-size: 14px;
  line-height: 22px;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: none;
}

/* section */
section{
  margin: 100px 0;

  &.inner-section {
    margin: 0;
    padding: 40px 0;

    &.blue {
      background-color: #373FB5;
    }
    &.grey {
      background-color: #F8FAFE;
    }
    &.contact {
      padding: 120px 0;

      h3 {
        margin-bottom: 20px;
      }
    }
    .section-title {
      margin: 0 auto;
      color: #fff;
      font-size: 30px;
      text-align: center;
    }
    .about-intro {
      max-width: 530px;
      text-align: center;

      img {
        margin: 30px auto 30px;
        max-width: 350px;
      }

      h3 {
        color: #FFFFFF;
        font-size: 28px;
        text-align: center;
        margin-bottom: 30px;
      }
      p {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }
    }

    .general-section {
      padding: 40px 0;
      h3 {
        font-size: 32px;
        margin-bottom: 30px;
      }
      .content {
        p, ul li {
          font-size: 18px;
          font-weight: 600;
        }
        &.lft-30 {
          padding-left: 30px;
        }
      }
      img {
        width: 100%;
      }
    }
    .contact-section {
      padding: 60px 0;
      p {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .vikram-info {
      > div {
        display: flex;
        align-items: center;
      }
      .image {
        float: left;
        margin-right: 30px;
        img {
          width: 190px;
        }
      }
      .content {
        h3 {
          color: #fff;
          font-size: 24px;
          margin-bottom: 0px;
        }
      }
      .footer-logo {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        
      }
    }
  }
}
section.three-items{
  margin: 30px 0 117px;
}


/* section items */
.list-box{
  background: #F6F9FC 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 15px;
}
.list-box li{
 font-size: 15px;
 position: relative;
 list-style: none;
 padding-left: 40px;
 display: grid;
}
.list-box li:first-child{
  margin-bottom: 10px;
}
.list-box li.red{
  color: #393939;
}
.list-box li.green{
  color: #000;
  font-weight: 600;
}
.list-box li.red::before{
  content: "\03a7";
  background-color: #D0021B;
  width: 26px;
  height: 26px;
  text-align: center;
  position: absolute;
  left: 0;
  border-radius: 50%;
  color: #FFF;
  font-weight: 600;
  line-height: 26px;
}
.list-box li.green::before{
  content: "";
  background-color: #42BD41;
  width: 26px;
  height: 26px;
  text-align: center;
  position: absolute;
  border-radius: 50%;
}

/* buttons */
.btn{
  font-size: 16px;
}
.btn-pink{
  background-color: #FF1D50;
  -webkit-box-shadow: 0px 2px 4px 0px #646464;
  -moz-box-shadow:    0px 2px 4px 0px #646464;
  box-shadow:         0px 2px 4px 0px #646464;
  border: 1px solid #FF1D50;;
  border-radius: 25px;
  color: #FFF;
  padding: 10px 15px;
  font-weight: 600;
}
.main .btn-pink {
  padding: 10px 15px;
  
  &:hover {
    color: #FF1D50;
  }
}
.main a:not([href]){
  color: #FFF;
}
.btn-pink:hover{
  background-color: transparent;
  box-shadow: 0px 2px 4px #64646480;
  border-radius: 25px;
  color: #FF1D50;
}
button.green{
  background-color: #00C36E;
  border-color: #00C36E;
}
button.green:hover{
  color: #00C36E;
}
button.no-bg-color, button.no-bg-color:hover{
  background-color: transparent;
  border-color: #232782;
  color: #232782;
  padding: 8px 35px;
}
button span{
  text-transform: uppercase;
  font-size: 16px;
}
/* four card 2/2 */
.card-view{
  background-color: rgba(0, 128, 128, 0.1);
  border-radius: 5px;
  padding: 15px;
  font-size: 13px;
  color: #4A4A4A;
  margin-top: 15px;
}
.card-view .name-title{
  font-weight: 800;
}
.card-view .name-title span{
  font-size: 13px;
  display: block;
  font-weight: 500;
}

/* header */
header .row{
  min-height: 65px;
}


/* footer */
footer{
  background-color: #232782;
  font-size: 14px;
  padding: 35px 0 30px;
}
footer .copyright{
  color: #FFFFFF5A;
}
footer a{
  color: #FFF;
  margin-left: 15px;
  border-left: 1px solid #FFF;
  height: 22px;
  padding-left: 15px;
  line-height: 22px;
}
footer a:first-child{
  border-left: 0 none;
}
footer a:hover{
  color: #FFF;
  text-decoration: underline;
}

/* inner pages */
.main{
  min-height: calc(100vh - 95px);
  padding: 30px 0;
}

.main.ssma, .main.adult-child, .main.symptoms, .prescription{
  background-color: #EDEEED;
}
.main h1{
  font-size: 24px;
  font-weight: 500;
  color: #232782;
}

.main span.secondary-title{
  font-size: 18px;
  font-weight: 500;

  img {
    vertical-align: middle;
  }
}


.ssma h2{
  color: #232782;
  font-size: 28px;
  font-weight: 600;
}
.ssma .cards{
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  position: relative;
  margin-top: 20px;
  min-height: 147px;
  cursor: pointer;
}
.ssma .card-blue{
  background: #232782;
}
.adult-child .card-white{
  background: #FFF url(/assets/icons/right.svg) calc(100% - 20px) center no-repeat;
  background-size: 18px auto;
}
.ssma .card-white{
  background: #FFF url(/assets/icons/right.svg) calc(100% - 20px) 88% no-repeat;
  background-size: 18px auto;
}
.ssma .card-blue > span{
  color: #9DA1FF;
  font-size: 12px;
  bottom: 20px;
}
.ssma .card-blue h4{
  color: #FFF;
  font-size: 15px;
}
.ssma .card-blue > h4 span{
  color: #FFF;
  font-size: 15px;
  font-weight: 600;
  display: block;
  padding-bottom: 5px;
}

.ssma .card-white > span{
  color: #9B9B9B;
  font-size: 12px;
  bottom: 20px;
}
.ssma .card-white h4{
  color: #000;
  font-size: 15px;
}
.ssma .card-white > h4 span{
  color: #232782;
  font-size: 15px;
  font-weight: 600;
  display: block;
  padding-bottom: 5px;
}

/* Home */

.four-steps{
  background-color: #F6F9FC; 
  padding: 100px 0;
}
.font-size{
  font-size: 15px;
}
.list-numbers{
  padding-left: 0;
}
.list-numbers li {
  display: inline-block;
  list-style: none;
  font-size: 14px;
}
.bg-list-items li{
  background-color: transparent;
  box-shadow: 0px 2px 4px transparent;
  display: table;
  padding: 3px 10px;
}
.bg-list-items li.active{
  background-color: #FFF;
  box-shadow: 0px 2px 4px #0000002A;
}
#owl-carousel2 .owl-item img{
  max-width: 80px;
}



/* contact */
ul.contact-details{
  color: #232782;
  font-size: 15px;
  padding-left: 0;
}
ul.contact-details li{
 margin-bottom: 10px;
 list-style: none;
 padding-left: 30px;
 padding-bottom: 0;
 background-position: left center;
 background-repeat: no-repeat;
}
ul.contact-details li:first-child{
  background-image: url(/assets/icons/location.svg); 
  background-size: 12px auto;
}
ul.contact-details li:nth-child(2){
  background-image: url(/assets/icons/email.svg); 
  background-size: 18px auto;
}
ul.contact-details li:last-child{
  background-image: url(/assets/icons/call.svg);
  background-size: 16px auto; 
}
.main h2{
  color: #232782;
  font-size: 20px;
  font-weight: 500;
}

.form-group {
  position: relative;
}
.contact .form-group label{
  color: #232782;
  background-color: #FFF;
  padding:2px 10px;
  position: absolute;
  top: -12px;
  left: 18px;
}
/* profile */

.profile-pic{
  background-color: #D8D8D8;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
}
.profile-pic img{
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.profile .form-group label{
  color: #9B9B9B;
  /* margin-top: 30px; */
}
.profile .form-group .form-control{
  border: 0 none;
  border-bottom: 1px solid #EDEDED;
  padding-left: 0;
}
.version{
  border-top: 1px solid #EDEDED;
  padding-top: 10px;
  color: #9B9B9B;
}

/* Registration / Sign In */
.regi-signin h2{
  color: #232782;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 7px solid transparent;
  cursor: pointer;
  position: relative;
}
.regi-signin h2::after{
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  height: 7px;
  width: 100%;
  background-color: #00C36E;
  visibility: hidden;
  content: "";
}
.regi-signin h2:hover::after, .regi-signin h2.active::after{
  visibility: visible;

}
.regi-signin button.btn-outline{
  border: 1px solid #232782;
  border-radius: 24px;
  padding: 13px 30px;
  font-size: 11px;
  color: #232782;
  display: inline-flex;
  min-width: 280px;
  text-align: center;
  justify-content: center;
  align-items: center;

  img {
    vertical-align: middle;
    max-width: 25px;
    margin-right: 12px;
  }
}
.regi-signin .or{
  color: #232782;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
}
.regi-signin .or::before{
  content: "";
  height: 1px;
  position: absolute;
  left: -100px;
  top: 8px;
  background-color: #dadee3;
  width: 90px;
}
.regi-signin .or::after{
  content: "";
  height: 1px;
  position: absolute;
  right: -100px;
  top: 8px;
  background-color: #dadee3;
  width: 90px;
}
.regi-signin .form-group label{
  color: #232782;
  background-color: #FFF;
  padding: 2px 10px;
}
.regi-signin .form-group .form-control{
  border: 1px solid #979797;
  display: inline-block;
  width: auto;
}
.regi-signin .form-group .form-control[name='mobileNumber']{
  padding-left: 0;
}
.regi-signin .form-inline .form-group label{
  position: relative;
  top: inherit;
  left: inherit;
}
.regi-signin .nav-tabs {
  border-bottom: 0 none;
}
.regi-signin .nav-tabs .nav-link {
  color: #232782;
  font-size: 24px;
  font-weight: 500;
  border: 0 none;
  margin: 0 15px;
  position: relative;
  padding: 15px 0;
}
.regi-signin .nav-tabs .nav-link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  height: 7px;
  width: 100%;
  background-color: #00C37E;
  visibility: hidden;
  content: "";
}
.regi-signin .nav-tabs .nav-link.active::after {
  visibility: visible;
}
.otpContainer {
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  input {
    width: 3.5em !important;
    height: 3.5em;
  }
}
.align-center {
  text-align: center;
}




/* adult / child */
.adult-child .cards{
  border-radius: 4px;
  width: 100%;
  position: relative;
  margin-top: 20px;
  padding: 15px;
  min-height: 70px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.adult-child .cards h4{
  font-size: 15px;
  color: #232782;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 20px;
  margin: 0;
}
.adult-child .cards h4 span{
  font-size: 11px;
  color: #9B9B9B;
}

/* carousel */
.owl-carousel .owl-item img {
  width: auto;
  display: inline-block;
}
.how-it-works .owl-nav{
  display: none;
}
.how-it-works .owl-dots{
  margin-top: 20px;
}

/* symptoms */
.main.symptoms {
  padding-top: 110px;
}
.symptoms .tab-content .tab-pane{
  background-color: #FFF;
  padding: 40px 30px;
  box-shadow: 0px 1px 4px #E7E7E9;
  border-radius: 5px;
}
.symptoms .tab-content h1{
  font-weight: 600;
  margin-top: -80px;
}
.symptoms .tab-content h1+span{
  margin-bottom: 30px;
  display: block;
  font-size: 15px;
}
.symptoms .nav-pills .nav-link{
  /* margin-right: 50px; */
  background-color: transparent;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  color: #9B9B9B;
  padding-left: 0;
}
.symptoms .nav-pills .nav-link span{
  background-color: #9B9B9B;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: #FFF;
  line-height: 20px;
  margin-right: 6px;
}
.symptoms .nav-pills .nav-link.active{
  color: #232782;
  border-color: #00C36E;
}
.symptoms .nav-pills .nav-link.active span{
  background-color: #00C36E;
}
.options-head{
  margin-top: 10px;
  position: relative;
}
.options-head span{
  background-color: #fff;
  position: relative;
  z-index: 99;
  padding-right: 10px;
}
.options-head::after{
  background-color: rgba(74, 74, 74, 0.15);
  position: absolute;
  height: 1px;
  content: "";
  width: 100%;
  top: 50%;
  left: 0;
}
.options-items span{
  background-color: #efefef;
  padding: 5px 20px;
  border-radius: 20px;
  margin-top: 20px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid transparent;
  margin-right: 12px;
  color: #000;
}
.options-items span:hover{
  background-color: transparent;
  border-color: #00C36E;
}
.options-items span.selected{
  background-color: #00C36E;
  color: #FFF;
}

.age-slider{
  margin-top: 30px;
  h4 {
    font-size: 15px;

    span {
      color: #000000;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.range-number{
  color: #000000;
}
.custom-control-label::before{
  border-color: #00C36E;
  top: 2px;
}
.custom-control-label::after{
  top: 2px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00C36E;
  background-color: #00C36E;
}

/* Prescription */
.main.header-title{
  padding-bottom: 0px;
  padding-top: 15px;
}
.header-title h1{
  font-weight: 600;
}
.block-head h5{
  color: #232782;
  font-weight: 900;
  line-height: 18px;
}
.block-head h5 span{
  color: #9B9B9B;
  font-weight: 400;
  font-size: 12px;
}
.block-head span.generic-name{
  color: #9B9B9B;
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
}
.block-head span.dosage{
  color: #232782;
  font-size: 14px;
  font-weight: 600;
}
.content-box{
  background-color: #FFF;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
}
.dosage-per-day{
  margin-top: 5px;
}
.dosage-per-day .timings{
  display: flex;
  flex-wrap: nowrap;
  background: #e4e4e4;
}
.dosage-per-day .timings > div:not(.alternativeDosage){
  width: 25%;
  border: 1px solid #edeeed;
}
.alternativeDosage {
  width: 100%;
  padding: 12px;
  font-size: 13px;
  color: #232782;
}
.dosage-per-day .timings .time img, .dosage-per-day .timings .time span{
  display: inline-block;
  vertical-align: middle;
}
.dosage-per-day .timings .time img{
  padding-right: 15px;
  height: 24px;
}
.dosage-per-day .timings .time{
  padding: 10px;
}
.dosage-per-day .timings .time span{
  line-height: 12px;
}
.content-box .all-day{
  color: #232782;
  font-size: 13px;
  padding: 10px;
  background: #EDEEED;
  margin-top: 20px;
}
.dosage-per-day .message{
  padding: 15px 15px;
  padding-left: 3px;
  color: #000;
}
.dosage-per-day .message ul{
  margin-left: 0;
  padding-left: 15px;
}
.dosage-per-day .timings .syrup-quantity{
  font-size: 15px;
  color: #232782;
  font-weight: 600;
  text-align: center;
  background-color: #fff;
  padding: 10px 0;
}
.need-to-do{
  color: #4A4A4A;
  font-size: 15px;
  margin-top: 20px;
}
.need-to-do h3.title {
  font-size: 18px;
  font-weight: 600;
  color: #676767;
  margin-bottom: 20px;
}
.need-to-do ul{
  color: #000000;
  font-size: 12px;
  margin-top: 10px;
  padding-left: 40px;
}
.need-to-do p{
  padding-left: 40px;
}
.my-profile {
  img.user {
    width:20px;
    margin-right: 12px
  }
  img.logout {
    width:20px;
  }
}
.center-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.sendemailbtn {
  width: 100%;
}
.user-details {
  background: #fff;
    span {
      margin-bottom: 12px;
      display: block;

      &.medication {
        margin-bottom: 0;
        color: #de6246;
        font-size: 14px;
      }
    }
    h1 {
        font-size: 30px;
        line-height: 30px;
    }
}
.custom-control.custom-radio {
  text-align: center;
}

.landing-section {
    height: 100vh;
    margin-top: 0;
    margin-bottom: 0;
    background-image: url('/assets/home-screen/Path 2272.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 60%;


    .container, .row {
      height: 100%;
    }
  }
header {

  &.fixed {

    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;
    background-color: transparent !important;
  }

  &.floating {
    background-color: #fff !important;
    -webkit-box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.54);
    -moz-box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.54);
    box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.54);
  }
}
.vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 510px;
  text-align: center;
  h2 {
    margin-bottom: 5px !important;
    font-size: 35px;
    text-align: left;
  }
  h3 {
    margin-bottom: 10px !important;
    font-size: 36px;
    text-align: left;
  }
  h5 {
    color: #000000;
    text-align: left;
  }
  .btn-pink {
    margin-top: 7em;
  }
}
.img-vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.covid-vaccination-banner {
    width: 100%;
  }
}
ul.list-items  {
  padding-left: 0;
  margin-left: 0;
  list-style: none;
  li {
    position: relative;
    font-size: 21px;
    color: #232782;
    margin-bottom: 15px;
    font-weight: 600;

    &:marker {
      content: none;
    }
    &:after {
      background-image: url('/assets/home-screen/right-arrow.svg');
      content: '';
      width: 17px;
      height: 17px;
      position: absolute;
      background-size: cover;
      left: -30px;
      top: 9px;
    }
  }
}
#situations-box {
  img {
    width: 100%;
  }
}
#how-it-works-box {
  .box {
    background: #F6F9FC 0% 0% no-repeat padding-box;
    border-radius: 8px;
    text-align: center;
    min-height: 380px;
    display: block;
    float: left;
    position: relative;
    margin-top: 0 !important;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    overflow: hidden;

    h3 {
      color: #232782;
      font-weight: 600;
      font-size: 22px;
      line-height: 52px;
      margin-top: 40px;
    }
    h5 {
      font-weight: 400;
      font-size: 18px;
      color: #000;
    }
    img {
      position:absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0%);
      margin: 0 auto;
      max-width: 60%;
      max-height: 45%;
    }
  }
}
.icon-box {
  display: flex;
  width: 100%;
  .icon {
    width: 25%;
    text-align: center;
  }
  .content {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin-bottom: 0;
    }
    .red {
      font-size: 20px;
      font-weight: normal;
      color: #707070;
    }

    .green {
      font-size: 20px;
      font-weight: 600;
      color: #232782;
    }
  }
}
.mobile {
  display: none;
}
.send-invitaiton {
  .content {
    text-align: left;
  }
}
.next-page {
  position: absolute;
  left: 50%;
  top: 90%;
  transform: translate(-50%, 0%);
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.54);
  -moz-box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.54);
  box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.54);
  text-align: center;

  img {
    width: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
}
.back-btn {
  img {
    width:30px;
  }
}
.back-btn-bg-color {
  background-color: transparent;
  color: #949494;
  border: 1px solid #949494;
}
.action-buttons {
  display: flex;
  button {
    flex-grow: 1;
    flex-basis: 0;

    &.downloadbtn {
      margin-right: 12px;
      color: #fff;
    }
  }
  .alert-msg {
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 0;
  }
}
.page {
  h3.title {
    text-align: center;
    margin-bottom: 30px;
  }
  h3:not(.title) {
    font-size: 14px;
    font-weight: normal;
    color: #000000;
  }
}
.login-title {
  margin: 0 auto;
}
.input-group-text {
  background-color: transparent;
  border-color: #979797;
}
.verify-otp-title {
  color: #232782;
  font-size: 18px;
  font-weight: 600;
}
.resend-otp { 
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  color: #FF1D50;
}
.resend-otp-div {
  margin-top: 20px;
  display: block;
}
.login-title {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 25px;
  font-size: 18px;
}
.downloadbtn {
  .download-icon {
    max-width: 20px;
    margin-right: 12px;
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #232782;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #fff;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  cursor: pointer;
}

.menuButton {
  cursor: pointer;
  img{
    width: 26px;
    margin-right: 12px;
  }
}
.messageBox {
  padding: 10px;
  background-color: #FF1D50;
  color: white;
  border-radius: 0;
  transition: all .3s;
  font-size: 16px;
  cursor: pointer;

  &.hide {
    display: none;
  }
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
.floating {
  .messageBox{
    display: none;
  }
}
.covid-treatment-landing {
  height: 575px;
  background-image: url('/assets/covid/covid-treatments.jpg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.treatments-footer {
  background-color: #232782;
  padding: 50px 0;
  text-align: center;
  color: #fff;

  h3 {
    color: #fff;
  }

  button {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  h3.care-msg {
    margin-bottom: 10px;
  }
}
.ribbon {
  border-bottom: 2px solid #232782;
  position: relative;
  margin: 30px 0;
  p {
    position: absolute;
    border-radius: 25px;
    background: #232782;
    padding: 6px 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}
.treatments-footer-white {
  background-color: #fff;
  padding: 50px 0;
  text-align: center;
  .footer-img {
    max-width: 500px;
  }
}
.prescription.covid-treatments {

  .content-box {
    .block-head {
      h5 {
        color: #454cea;
        span {
          color: #232782;
        }
      }
      span.dosage {
        color: #454cea;
      }
    }
    .dosage-per-day {
      .timings { 
        .syrup-quantity {
          color: #454cea;
        }
      }
    }
  }
  .privacy {
    border-bottom: 1px solid #232782;
    text-align: center;
    margin: 50px 0;
    font-size: 16px;
  }
  .content-box {
    margin-bottom: 20px;
    -webkit-box-shadow: 6px 6px 10px -2px rgba(0,0,0,0.31);
    -moz-box-shadow: 6px 6px 10px -2px rgba(0,0,0,0.31);
    box-shadow: 6px 6px 10px -2px rgba(0,0,0,0.31);
  }
  .instructions {
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 4px solid #edeeed;
  }
  .dosage-per-day {
    .message {
      font-size: 15px;

      ul {
        list-style: none;
        padding-left: 0;
        margin-left: 0;

        li {
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
@media screen and (-webkit-min-device-pixel-ratio:0) {

  input[type='range']::-webkit-slider-runnable-track {
      height: 1px;
      -webkit-appearance: none;
    }
  
  input[type='range']::-webkit-slider-thumb {
    background: #00C36E;
    margin-top: -7px;
  }

}



/* Corona */
.corona-page{
  box-shadow: none;
  line-height: 22px;
}
.corona-page section{
  padding: 30px 0 0;
  margin: 0;
}
.corona-page h1{
  font-weight: 600;
  font-size: 32px;
}
.corona-page h2{
  font-weight: 600;
  font-size: 22px;
}
.corona-page h3{
  font-size: 18px;
  font-weight: 400;
}
.corona-page .options-items span{
  font-size: 14px;
  line-height: 22px;
}
.language-nav{
  padding-left: 0;
  margin-left: 0;
}
.language-nav li{
  display: inline-block;
}
.language-nav li a{
  padding: 0 10px;
  font-size: 15px;
  color: #9B9B9B;
  
}
.language-nav li a.active{
  color: #232782;
}
.travel-history{
  background-color: #EDEEED;
}
.item-container{
  background-color: #FFF;
  border-radius: 5px;
  padding: 30px 10px;
  margin-top: 10px;
}
.item-container h3+span{
  font-size: 16px;
  color: #4A4A4A;
}
.container.footer a{
  margin: 0 5px;
}
















@media (min-width: 768px){
  header{
      background-color: #FFF;
      box-shadow: none;
      }
  .profile .form-group label{
      margin-top: 30px;
  }
  .regi-signin:not(.relative) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  h2 {
      font-size: 38px;
  }
  h5{
      font-size: 18px;
      line-height: 30px;
  }
  h4{
      font-size: 20px;
      margin-bottom: 15px;
  }
  .font-size{
      font-size: 15px;
  }
  .list-box li{
      font-size: 20px;
   }
   .card-view{
      font-size: 18px;
  }
  h3{
      font-size: 28px;
  }
  #owl-carousel2 .owl-item{
      width: 50% !important;
  }
  .adult-child .tab-content > .tab-pane {
      display: block;
      opacity: 1;
  }
  .adult-child .nav-tabs .nav-link{
      color: #232782;
      font-size: 28px;
      font-weight: 500;
      padding: 0;
  }
  .adult-child h2{
      color: #232782;
      font-size: 28px;
      font-weight: 500;
      padding: 0;
  }
  .adult-child .nav-tabs .nav-link span {
      font-size: 18px;
      color: #5A5959A1;
  }
  .adult-child .nav-tabs .nav-link.active {
      background-color: transparent;
      color: #232782;
      border: 0 none;
  }
  .adult-child .nav-tabs{
      border: 0 none;
  }
}
.instructions {
  font-size: 15px;
}
.mobile {
  display: none;
}
@media (max-width: 600px){
  .treatments-footer-white {
    .footer-img {
      max-width: 100%;
    }
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .get-advice-btn {
    margin: 0 auto;
    display: block;
    width: fit-content;
  }
  .main h1 {
    font-size: 20px;
  }
  .main span.secondary-title {
    font-size: 14px;
  }
  .main.symptoms {
      padding-top: 10px;
  }
  .main.symptoms h1 {
      font-size: 15px;
  }
  .symptoms .nav.nav-pills{
      display: block;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
  }
  .symptoms .tab-content h1 {
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 20px;
  }
  .symptoms .nav-pills .nav-link{
      display: inline-block;
      width: calc(33.33% - 2px);
      padding: 10px 0;
      margin-bottom: 20px;
  }
  .adult-child .nav-tabs .nav-link {
      border-radius: 0;
      padding: 10px 0;
      width: 50%;
      text-align: center;
      font-size: 18px;
      background-color: transparent;
      border-bottom: 2px solid #dee2e6;
      color: #000000;
  }
  .adult-child .nav-tabs .nav-link span {
      font-size: 10px;
  }
  .adult-child .nav-tabs .nav-link.active {
      color: #232782;
      background: transparent;
      font-weight: 600;
      border: 0;
      border-bottom: 2px solid #232782;
  }
  .tab-headers > div{
      padding-left: 0;
      padding-right: 0;
  }
  .dosage-per-day .timings .time span{
    padding-top: 5px;
    font-size: 10px;
  }
  .dosage-per-day .timings .time img, .dosage-per-day .timings .time span{
    display: block;
  }
  .dosage-per-day .timings > div {
    border: 1px solid #edeeed;
  }
  .dosage-per-day .timings .time {
    padding: 6px;
    text-align: center;
  }
  .dosage-per-day .timings .time img {
    padding-right: 0;
    height: 18px;
    margin: 0 auto;
  }
  .block-head h5 {
    font-size: 15px;
  }
  .block-head span.dosage{
    font-size: 15px;
  }
  .vertical-center {
    position: relative;
    text-align: center;
    transform: none;
    left: auto;
    top: auto;
    max-width: 350px;
    margin: 0 auto;
    margin-top: 90px;

    h2 {
      margin-bottom: 10px;
      font-size: 21px;
      text-align: center;
    }
    h3 {
      font-size: 24px;
      margin-bottom: 10px;
      text-align: center;
    }
    h5 {
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      color: #000000;
      text-align: center;
      margin-top: 18px;
    }
  }
  #how-it-works-box {
    .box {
      min-height: 380px;
    }
  }
  .landing-section {
    background: none;
      .container, .row {
        height: auto;
      }
  }
  .img-vertical-center {
    position: relative;
    width: 80%;
    height: calc(100vh - 110%);
    margin: 0 auto;
    top: auto;
    left: auto;
    transform: none;
    margin-top: 15px;
  }
  .web {
    display: none;
  }
  .mobile {
    display: inline-block;
  }
  .btn-pink {
    font-size: 15px;
  }
  .img-all {
    width: 90%;
    margin-left: 5%;
  }
  ul.list-items {
    padding-left: 30px;
    
    li {
      font-size: 16px;

      &:after {
        width: 14px;
        height: 14px;
        left: -24px;
        top: 6px;
      }
    }
  }
  #situations-box {
    img {
      margin-bottom: 20px;
    }
  }
  .col-reverse {
    flex-direction: column-reverse;
  }
  .icon-box {
    .icon {
      img {
        width: 100%;
      }
    }
    .content {
      .red {
        font-size: 14px;
      }
      .green {
        font-size: 16px;
      }
    }
  }
  .send-invitaiton {
    .content {
      text-align: center;
      margin-top: 20px;

      h3 {
        text-align: left;
        font-size: 23px;
      }
    }
  }
  section{
    &.inner-section {

      .general-section {
        .about-image {
          max-width: 70%;
          margin: 25px auto;
          display: block;
        }
        .content {
          &.lft-30 {
            padding-left: 0px;
          }
        }
      }
      .vikram-info {
        .image {
          img {
            width: 90px;
          }
        }
        .content {
          h3 {
            font-size: 20px;
          }
        }
        .footer-logo {
          margin-top: 30px;
        }
        .mt {
          margin-top: 20px;
        }
      }
    }
  }
  .messageBox {
    font-size: 12px;
  }
  .addMessageBoxMargintTop {
    margin-top: 130px;
  }

  .covid-treatment-landing {
    max-height: 168px;
  }
}
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

  .img-vertical-center {
    height: 120%;
  }

}
//coming soon

.bgimg {
  background-image: url('/assets/home-screen/coming-soon.jpg');
  height: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
}

.topleft {
  position: absolute;
  top: 20px;
  left: 16px;
}

.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
#tab2.tab-pane {
  text-align: center;
  .age-slider {
    text-align: left;
  }
}
.btn-group-toggle {
  margin: 0 auto;

  .btn {
    border-radius: 20px;
    min-width: 120px;
    border: 0;
    background-color: #efefef;
    color: #000;
    box-shadow: none;

    &.active {
      background-color: #00c36e !important;
      color: #fff;
      border: 0;
    }
  }
}
.btn-group>.btn:not(:first-child) {
  margin-left: 0px;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}
.back,.next {
  padding: 10px 25px !important;
}
.spinner-center {
  position: absolute;
  top: 50%;
  left: 45%;
  text-align: center;

  strong {
    display: block;
    clear: both;
  }
}